/* v8 ignore start */
<script>
import LandingImageComponent from '@rei/landing-image-component';
import { LandingContentBlock } from '@rei/landing-content-block';
import { palettes } from '@rei/landing-palettes';

export default {
  name: 'LeadCarouselCard',
  components: {
    LandingImageComponent,
    LandingContentBlock,
  },
  props: {
    media: { type: Object, required: false, default: () => null },
    content: { type: Object, required: false, default: () => {} },
    id: { type: String, required: false, default: () => '99' },
    slideIndex: { type: String, required: false, default: () => '' },
    imageOnly: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      palettes: palettes(this.content?.config.palette),
    };
  },
  computed: {
    cssVars() {
      return {
        ...this.palettes.cssSurfacePaletteVars(),
      };
    },
  },
  methods: {
    btnClick(event) {
      if (!event.target.href) return;
      const linkText = event.target.textContent.trim();
      window.metrics?.link({
        linkName: `ct_1_lead_${+this.slideIndex + 1}_${linkText}`,
      }, event.target.href);
    },
  },
};
</script>
<template>
  <div
    class="lead-carousel-card"
    :style="cssVars"
  >
    <div class="lead-carousel-card__hero">
      <landing-image-component
        v-if="media"
        v-bind="media"
        :lazy-load="false"
        fetch-priority="high"
        data-ui="lead-component-image"
      />
    </div>
    <div
      v-if="!imageOnly"
      class="lead-carousel-card__content"
    >
      <landing-content-block
        v-if="content"
        data-ui="lead-component-content"
        v-bind="content"
        @click.capture.stop="btnClick"
      />
      <p v-else>Some test text goes here, yeah?</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

.lead-carousel-card {
  display: grid;
  width: 100%;
  height: 100%;
  background: var(--palette-background-color);
  scroll-snap-align: center;
  grid-template-columns: 100%;
  padding-bottom: 16px;

  &__hero {
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    vertical-align: middle;
    text-align: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height: 300px;
    max-height: 300px;
    overflow: hidden;
    aspect-ratio: 375/300;

    :deep(.landing-image-component) {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    :deep(.landing-image-component__image) {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    @include cdr-sm-mq-up {
      max-height: 329px;
      aspect-ratio: 768/329;
    }
    @include cdr-md-mq-up {
      max-height: 425px;
      aspect-ratio: 992/425;
    }
    @include cdr-lg-mq-up {
      max-height: 530px;
      aspect-ratio: 1600/530;
    }

    p {
      position: absolute;
      font-size: 20rem;
      padding: 0;
      margin: 0;
    }
  }
  &__content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
    text-align: center;
    padding: 16px 16px 45px;

    @include cdr-sm-mq-up {
      padding: 32px 32px;
    }

    .ls-content-block {
      max-width: 672px;

      @include cdr-sm-mq-up {
        max-width: 672px;
      }
      @include cdr-md-mq-up {
        max-width: 840px;
      }
    }

    :deep(.landing-image-component__image) {
      display: none !important;
    }

    :deep(a) {
      text-align: center;
    }
  }
}
</style>
/* v8 ignore stop */
