/* v8 ignore start */
<script>
import { CdrButton, IconArrowLeft, IconArrowRight } from '@rei/cedar';
import LeadCarouselCard from './LeadCarouselCard.vue';

import LeadCarouselTestData from './LeadCarouselTestData.json';

/** @type IntersectionObserver */
let observer = null;

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default {
  name: 'LeadCarousel',
  components: {
    CdrButton,
    IconArrowLeft,
    IconArrowRight,
    LeadCarouselCard,
  },
  props: {
    media: { type: Object, required: false, default: () => {} },
    content: { type: Object, required: false, default: () => {} },
    loopOnEnd: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      isLoaded: false,
      slideIndex: 0,
      dotIndex: 0,
      nextSlide: -1,
      dotClicked: false,
      leftDotClicked: true,
      blockDots: false,
    };
  },
  computed: {
    visibleSlides() {
      const { nextSlide } = this;
      const i = this.slideIndex;
      let first = this.getLoopIndex(true, i);
      const mid = i;
      let last = this.getLoopIndex(false, i);
      first = nextSlide > -1 && nextSlide < mid ? nextSlide : first;
      last = nextSlide > -1 && nextSlide > mid ? nextSlide : last;
      return [
        { id: `slide-${this.slideIndex - 1}`, slideIndex: `${first}`, data: this.slides[first] },
        { id: `slide-${this.slideIndex}`, slideIndex: `${mid}`, data: this.slides[mid] },
        { id: `slide-${this.slideIndex + 1}`, slideIndex: `${last}`, data: this.slides[last] },
      ];
    },
    preloadSlides() {
      return this.slides.map((s, i) => ({ id: `slide-${i}`, slideIndex: '', data: s }));
    },
    slides() {
      return LeadCarouselTestData;
    },
  },
  watch: {
    slideIndex(newIndex, oldIndex) {
      if (newIndex !== oldIndex) this.dotIndex = newIndex;
    },
  },
  mounted() {
    // Scroll to center object instantly
    this.centerScroll();
    this.isLoaded = true;
    // Setup observer
    const options = {
      root: this.$refs.wrapper,
      threshold: 0.8,
    };
    observer = new IntersectionObserver(debounce(this.slideVisible), options);
    this.$nextTick(this.initObserver);
  },
  unmounted() {
    observer.disconnect();
  },
  beforeUpdate() {
    this.centerScroll();
  },
  updated() {
    if (this.dotClicked) {
      // Make sure we're centered
      this.centerScroll();
      if (this.leftDotClicked) this.previous();
      else this.next();
      this.dotClicked = false;
    } else {
      const img = this.$refs.slides[1].$el.querySelector('.lead-carousel-card__hero img');
      if (img.complete) this.centerScroll();
      else img.onload = this.centerScroll;
    }
  },
  methods: {
    getLoopIndex(isPrev, index = this.slideIndex) {
      const increment = isPrev ? -1 : 1;
      // Idea thanks to: https://dev.to/jvon1904/how-to-wrap-around-a-range-of-numbers-with-the-modulo-cdo
      return (index + this.slides.length + increment) % this.slides.length;
    },
    next() {
      if (!this.dotClicked) this.dotIndex = this.getLoopIndex(false);
      this.$nextTick(() => {
        this.$refs.wrapper.scrollTo({ left: this.$refs.wrapper.offsetWidth * 2 + 20, behavior: 'smooth' });
      });
      window?.metrics?.click({
        linkName: `ct_1_${'next slide'}`,
      });
    },
    previous() {
      if (!this.dotClicked) this.dotIndex = this.getLoopIndex(true);
      this.$nextTick(() => {
        this.$refs.wrapper.scrollTo({ left: -this.$refs.wrapper.offsetWidth - 10, behavior: 'smooth' });
      });
      window?.metrics?.click({
        linkName: `ct_1_${'previous slide'}`,
      });
    },
    dotClick(i) {
      const index = parseInt(i, 10);
      if (index === this.slideIndex || this.blockDots) return;
      this.blockDots = true;
      this.dotClicked = true;
      this.nextSlide = index;
      this.dotIndex = index;
      this.leftDotClicked = this.nextSlide < this.slideIndex;
      // Block for rapid clicking navigation dots
      setTimeout(() => {
        this.dotClicked = false;
        this.blockDots = false;
      }, 1000);
    },
    initObserver() {
      if (observer) {
        const markers = [...this.$refs.wrapper.querySelectorAll('.slide-marker')];
        markers.forEach((s) => observer.observe(s));
      }
    },
    centerScroll() {
      this.$refs.wrapper.scrollLeft = this.$refs.slides[1].$el.offsetWidth;
    },
    slideVisible(entries) {
      let newSlideIndex = this.slideIndex;
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (this.nextSlide > -1) {
            newSlideIndex = this.nextSlide;
            return;
          }
          const pos = parseInt(entry.target.dataset.index, 10);
          if (pos === 1) return;
          if (pos < 1) {
            newSlideIndex -= 1;
            if (newSlideIndex < 0) newSlideIndex = this.slides.length - 1;
          } else {
            newSlideIndex += 1;
            if (newSlideIndex > this.slides.length - 1) newSlideIndex = 0;
          }
        }
      });
      if (this.slideIndex !== newSlideIndex) {
        this.slideIndex = newSlideIndex;
        this.nextSlide = -1;
      }
    },
  },
};
</script>
<template>
  <div
    class="lead-carousel"
    data-ui="lead-carousel"
    data-analytics-config="exclude-all"
  >
    <!-- eslint-disable vue/require-v-for-key -->
    <!-- eslint-disable vue/no-v-for-template-key -->
    <div
      class="lead-carousel__wrapper"
      :class="{ loading: !isLoaded }"
    >
      <div class="lead-carousel__controls">
        <div class="lead-carousel__controls-wrapper">
          <div class="scroll-btn scroll-left">
            <cdr-button
              icon-only
              with-background
              aria-label="Scroll to previous slide"
              @click.prevent.stop="previous"
            >
              <icon-arrow-left />
            </cdr-button>
          </div>
          <div class="scroll-btn scroll-right">
            <cdr-button
              icon-only
              with-background
              aria-label="Scroll to next slide"
              @click.prevent.stop="next"
            >
              <icon-arrow-right />
            </cdr-button>
          </div>
        </div>
      </div>
      <div
        ref="wrapper"
        class="lead-carousel__slide-wrapper"
        aria-live="polite"
      >
        <div
          class="slide-marker left"
          data-index="0"
        />
        <lead-carousel-card
          v-for="(slide, index) in visibleSlides"
          v-bind="slide.data"
          :id="slide.id"
          ref="slides"
          :key="`slide-${slide.id}`"
          :slide-index="slide.slideIndex"
          :aria-hidden="index !== 1"
          :inert="index === 1 ? false : true"
        />
        <div
          class="slide-marker right"
          data-index="2"
        />
      </div>
      <div
        class="preload-slides"
        inert
      >
        <lead-carousel-card
          v-for="slide in preloadSlides"
          v-bind="slide.data"
          :id="slide.id"
          ref="preloadSlides"
          :key="`slide-${slide.id}`"
          :slide-index="slide.slideIndex"
          :aria-hidden="true"
          :image-only="true"
        />
      </div>
      <div class="lead-carousel__dot-wrapper">
        <template
          v-for="(slide, index) in slides"
          :key="`dot-${index}`"
        >
          <button
            class="lead-carousel__dot"
            :class="{ 'active': dotIndex === index }"
            :aria-label="`Click to load slide ${index + 1}`"
            @click.prevent.stop="dotClick(index)"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

.lead-carousel__wrapper {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-height: 600px;
  background: #fff;

  @include cdr-sm-mq-up {
    max-height: 854px;
  }
  @include cdr-md-mq-up {
    max-height: 854px;
  }
  @include cdr-lg-mq-up {
    max-height: 854px;
  }

  &.loading {
    opacity: 0;
  }

  .lead-carousel__controls {
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 1800px;
    pointer-events: none;
    z-index: 1;
    min-height: 300px;
    max-height: 329px;
    aspect-ratio: 768/329;
    display: none;

    @include cdr-sm-mq-up {
      display: grid;
    }

    @include cdr-md-mq-up {
      max-height: 425px;
      aspect-ratio: 992/425;
    }
    @include cdr-lg-mq-up {
      max-height: 530px;
      aspect-ratio: 1600/530;
    }
  }

  .lead-carousel__controls-wrapper {
    display: flex;
    width: 100%;
    max-height: 600px;
    justify-content: space-between;

    .scroll-btn {
      margin: auto 8px;
      width: 4rem;
      height: 4rem;
      vertical-align: middle;
      text-align: center;
      pointer-events: all;

      @include cdr-sm-mq-up {
        margin: auto 40px;
      }
    }
  }

  .lead-carousel__dot-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto 24px;
    z-index: 1;

    @include cdr-sm-mq-up {
      margin-bottom: 32px;
    }

    .lead-carousel__dot {
      width: 16px;
      height: 16px;
      background-color: #fff;
      border: 0.5px solid $cdr-color-border-secondary;
      border-radius: 20px;
      cursor: pointer;
      padding: 0;
      margin: 0;

      &.active {
        background-color: $cdr-color-icon-emphasis;
      }
    }
  }

  .lead-carousel__slide-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1px 100% 100% 100% 1px;
    grid-template-rows: 100%;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    // Scrollbar logic
    scroll-snap-stop: normal;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    .slide-marker {
      position: relative;
      width: 1px;
      height: 100%;
      left: 2px;
      z-index: -1;

      &.right {
        left: -2px;
      }
    }
  }

  .preload-slides {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -20;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;

    > div {
      height: 100px;
      overflow: hidden;
    }
  }
}
</style>
/* v8 ignore stop */
